<template>
  <div>
    <h3>Смена №</h3>
    <v-container grid-list-md text-xs-center>
      <v-layout row wrap>
        <v-flex xs12 md3>
          <h3>Финансы</h3>
          <v-layout row wrap>
            <v-card
              color="blue darken-2"
              class="white--text"
              v-for="(row, idx) in tableData"
              v-bind:key="idx"
            >
              <v-card-title primary-title>
                <div class="headline">{{ row.cost_tpe_name }}</div>
              </v-card-title>
              <v-card-text class="white">
                <h1 style="text-align: center" class="black--text">
                  {{ row.balance }} р.
                </h1>
              </v-card-text>
              <v-card-actions class="white">
                <v-btn color="success" dark @click="edit(row, 'plus')">
                  <v-icon dark>add</v-icon> Приход</v-btn
                >
                <v-btn color="error" dark @click="edit(row, 'minus')">
                  <v-icon dark>remove</v-icon> Расход</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-layout>
        </v-flex>
        <v-flex xs12 md9>
          <h3>История операций</h3>
          <v-data-table
            v-bind:headers="headers"
            :items="history"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.dte }}</td>
                <td class="text-xs-right">{{ props.item.cost_tpe_name }}</td>
                <td class="text-xs-right">{{ props.item.comment }}</td>
                <td class="text-xs-right">{{ props.item.customer_name }}</td>
                <td class="text-xs-right">{{ props.item.user_fio }}</td>
                <td class="text-xs-right">{{ props.item.cost }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>

    <modal_edit
      v-if="modal"
      v-bind:view="modalView"
      v-on:close="modalClose"
    ></modal_edit>
    <modal_delete
      v-if="modal_delete"
      v-bind:view="modalView"
      v-on:close="modalClose"
    ></modal_delete>
  </div>
</template>

<script>
import modal_edit from './cashbox_edit.vue'
import modal_delete from './cashbox_delete.vue'

export default {
  data() {
    return {
      headers: [
        { text: 'Дата', align: 'left', value: 'dte' },
        { text: 'Счет', align: 'left', value: 'cost_tpe_name' },
        { text: 'Комментарий', align: 'left', value: 'comment' },
        { text: 'Клиент', align: 'left', value: 'customer_name' },
        { text: 'Пользователь', align: 'left', value: 'user_fio' },
        { text: 'Сумма', value: 'cost' },
      ],

      tableData: [],
      history: [],
      modal: '',
      modal_delete: '',
      modalView: '',
    }
  },
  components: {
    modal_edit,
    modal_delete,
  },
  mounted: function () {
    this.dataLoad()
    this.historyLoad()
  },
  methods: {
    modalClose: function (prm) {
      this.modal = ''
      this.modal_delete = ''
      if (prm == 'reload') {
        this.dataLoad()
        this.historyLoad()
      }
    },
    modalDelClose: function (prm) {
      this.modal_delete = ''
      if (prm == 'reload') {
        this.modal = ''
        this.dataLoad()
      }
    },
    dataLoad: function () {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'cashbox').then(
        (response) => {
          if (response.body.err > 0) {
            t.tableData = ['error']
          } else {
            t.tableData = response.body
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },
    historyLoad: function () {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'cashbox/history').then(
        (response) => {
          if (response.body.err > 0) {
            t.history = ['error']
          } else {
            t.history = response.body
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },
    edit: function (id, oper) {
      this.modal = true
      this.modalView = { oper: oper, cost_tpe: id.cost_tpe + '' }
    },
  },
}
</script>

<style></style>
