<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title class="headline" v-if="view.oper == 'plus'"
        >Приход денег</v-card-title
      >
      <v-card-title class="headline" v-if="view.oper == 'minus'"
        >Расход денег</v-card-title
      >
      <v-card-text>
        <v-form v-model="valid" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 sm6>
              <v-text-field
                v-model="view.cash"
                label="Сумма"
                type="number"
                :rules="[(v) => !!v || 'Поле должно быть заполнено']"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-select
                v-model="view.cost_tpe"
                v-bind:items="cost_tpe"
                label="Счет"
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="view.comment"
                label="Примечание"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat="flat" @click.native="$emit('close')">Отменить</v-btn>
        <v-btn color="primary" dark @click.native="save">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['view'],
  data() {
    return {
      dialog: true,
      valid: false,
    }
  },
  computed: {
    cost_tpe: function () {
      return this.$store.state.spr.cost_tpe
    },
  },
  mounted: function () {
    this.$store.dispatch('loadCostTpe')
  },
  methods: {
    save: function () {
      var t = this
      var arr = Object.assign({}, this.view)

      if (t.valid) {
        this.$http
          .post(this.$store.state.apiUrl + 'cashbox/save/', {
            cash: arr,
          })
          .then(
            (response) => {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: response.body.msg,
              })
              if (!response.body.err) {
                this.$emit('close', 'reload')
              }
            },
            (err) => {
              console.log(err)
            }
          )
      }
    },
  },
}
</script>

<style></style>
